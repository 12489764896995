@import '~font-awesome';

html {
    scroll-behavior: smooth;
}
.clicct-menu {
    background-color: transparent;
    border: 0px;
    color: #FFF;
}
.clicct-menu.changeColor {
    background-color: grey;
    transition: 0.3s all;
}
.navbar-default .navbar-brand {
    padding-left: 200px;
    padding-right: 200px;
    color: #FFF;
    height: auto;
}
.navbar-default .navbar-nav>li>a {
    color: #FFF;
}
.navbar-default .navbar-nav > li > a:focus, .navbar-default .navbar-nav > li > a:hover {
    color: #333;
    background-color: transparent;
}
.clicct-menu-demo {
    color: #1ebb9c !important;
}
.clicct-demo-button-outside {
    width: 100%;
    text-align: center;
    margin-top: 1.2em;
}
.clicct-button-demo {
    margin: auto;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 50px;
    padding-right: 50px;
    font-size: 2.0em !important;
    background-color: #1ebb9c !important;
    border: 0px;
}
.clicct-menu-title {
    width: 100%;
    color: #fff;
    z-index: 1;
    margin-left: 0px;
    margin-top: 5.0em;
    font-size: 4.0em !important;
    font-weight: bold;
    text-align: center;
}
.clicct-main-break {
    text-align: center;
    font-size: 1.9em !important;
    font-weight: bold;
    background-color: #1ebb9c !important;
    color: #FFF;
    margin: 40px 0 0 0;
    padding-top: 50px;
    padding-bottom: 50px;
}
.triangle {
    width: 0;
    border-top:60px solid #1ebb9c;
    border-left:50px solid white; 
    border-right:50px solid white;
    margin: auto;
}
footer {
    padding: 80px;
    margin: auto;
    text-align: center;
}
.clicct-feature {
    padding: 60px;
    padding-bottom:10px;
}
.clicct-service {
    padding:30px;
    margin: 30px;
    background-color: #f2f2f2;
}

.clicct-main-text {
    line-height: 1.2;
}


.contact-form {
    padding: 40px;
}
label {
    margin-top:2px;
}

/*!
 * Start Bootstrap - Scrolling Nav HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

 body {
    width: 100%;
    /*font-size: 16px;*/
}

html {
    width: 100%;
    height: 100%;
}

@media (max-width:767px) {
    /*body{font-size: 8px;}*/
}

@media (min-width:768px) {
    /*body{font-size: 12px;}*/
    /*body {
        font-size: 1.5rem;
    }
    body .container {
        max-width: 768px;
        margin: 0;
    }
    .form-control {
        font-size: 1.5rem;
    }
    .lead {
        font-size: 1.5rem;
    }
    .btn-sm {
        font-size: 1.5rem;
    }
    .btn {
        font-size: 1.5rem;
    }
    h5 {
        font-size: 1.5rem;
    }*/
    .navbar {
        padding: 20px 0;
        -webkit-transition: background .5s ease-in-out,padding .5s ease-in-out;
        -moz-transition: background .5s ease-in-out,padding .5s ease-in-out;
        transition: background .5s ease-in-out,padding .5s ease-in-out;
    }

    .top-nav-collapse {
        padding: 0;
    }
}
@media (min-width:992px) {
    /*body{font-size: 12px;}*/
}
@media (min-width:1200px) {
    /*body{font-size: 14px;}*/
}


/* Demo Sections - You can use these as guides or delete them - the scroller will work with any sort of height, fixed, undefined, or percentage based.
The padding is very important to make sure the scrollspy picks up the right area when scrolled to. Adjust the margin and padding of sections and children 
of those sections to manage the look and feel of the site. */
.clicct-header {
    height: 100vh;
    background:url('clicct-mains.jpg') no-repeat center center;
    background-size:cover;
    /* Workaround for some mobile browsers */
    min-height:100%;    
    padding-top: 20px;
    padding-bottom: 20px;
        }
.intro-section {
    padding-top: 60px;
    text-align: center;
    background: #fff;
}



.services-section {
    padding-top: 100px;
    text-align: center;
    background: #fff;
}


.hotelbedimg_SGL {	background-position: -65px -475px !important; } 
.hotelbedimg_DBL {	background-position: -35px -475px !important; }
.hotelbedimg_TWN {	background-position: -65px -475px !important; }
.hotelbedimg_MAP {  
    background: url('/build/images/beds_in_plan_transparent.png'); 
    background-size: 200px 170px;
    display: inline-block;
}
